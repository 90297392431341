import { bool, func, string } from "prop-types";
import { Typography, makeStyles, ButtonBase } from "@material-ui/core";
import classNames from "classnames";

import theme from "../../globalTheme";

const useStyles = makeStyles(() => ({
    root: {
        border: "none",
        borderRadius: "20px",
        padding: "6px 9px",
        width: "100%",
        cursor: "pointer",
    },
}));

const ButtonSearch = (props) => {
    const styles = useStyles();

    return (
        <ButtonBase
            className={classNames(styles.root, props?.className)}
            style={{
                backgroundColor: !props?.disabled ? theme.palette.primary.light : theme.palette.secondary.dark,
                color: !props?.disabled ? theme.palette.common.white : theme.palette.secondary.main,
            }}
            onClick={() => props?.onClick()}
            disabled={props?.disabled}
        >
            <Typography variant="h6">{props?.children}</Typography>
        </ButtonBase>
    );
};

ButtonSearch.propTypes = {
    onClick: func,
    children: string,
    className: string,
    disabled: bool,
};

export default ButtonSearch;
