import { Box, Button, Typography, makeStyles } from "@material-ui/core";
import theme from "../globalTheme";
import imgLetterFeretBg from "../assets/feretLetter/letter_ferret_fioritures.png";
import imgLetterFeretTxt from "../assets/feretLetter/letter_feret_logo.png";
import imgBenedicteMartre from "../assets/feretLetter/feret_benedicte_cover.webp";
import imgWineDegustation from "../assets/feretLetter/feret_wine_degustation.webp";
import imgWineFields from "../assets/feretLetter/feret_banner_wine_fields.png";
import imgFeretBubble from "../assets/feretLetter/feret_bubble.png";
import imgTasters from "../assets/feretLetter/pictureTasters.png";

import imgTasterBernard from "../assets/feretLetter/taster_bernard.png";
import imgTasterEtienne from "../assets/feretLetter/taster_etienne.png";
import imgTasterGianni from "../assets/feretLetter/taster_gianni.png";
import imgTasterHenry from "../assets/feretLetter/taster_henry.jpg";

import imgWave from "../assets/feretLetter/wave.svg";
import fioOne from "../assets/feretLetter/fioritures/fio1.webp";
import fioTwo from "../assets/feretLetter/fioritures/fio2.webp";
import fioThree from "../assets/feretLetter/fioritures/fio3.webp";
import fioFour from "../assets/feretLetter/fioritures/fio4.webp";
import fioFive from "../assets/feretLetter/fioritures/fio5.webp";

import imgLogo from "../assets/feretLetter/feretInline.svg";

import { Link, useLocation } from "react-router-dom";
import { useCustomHistory } from "hooks";
import classNames from "classnames";
import { useEffect, useLayoutEffect, useRef } from "react";

const useStyles = makeStyles((e) => ({
    feretLetterHeader: {
        fontFamily: "Playfair Display",
        backgroundImage: `url(${imgLetterFeretBg})`,
        backgroundSize: "cover",
        minHeight: "250px",
        width: "100%"
    },
    feretBodyContainer: {
        overflow: "hidden",
        position: "relative",
        display: "flex",
        flexDirection: "column",
    },
    feretLetterBody: {
        position: "relative",
        padding: "25px 15px",
        maxWidth: "1000px",
        margin: "0 auto",
        display: "flex",
        flexDirection: "column",
        gap: "2em",
        overflow: "visible",
    },
    feretLetterBodyWithWrapper: {
        position: "relative",
        overflow: "hidden",
    },
    feretLetterBodyWithBgWrapper: {
        backgroundColor: "#EDE5E5"
    },
    feretLetterBodyWithBg: {
        padding: "25px 15px",
        maxWidth: "1000px",
        margin: "0 auto",
        display: "flex",
        flexDirection: "column",
        gap: "2em",
        backgroundColor: "#EDE5E5"
    },
    feretLetterSubHeader: {
        display: "flex",
        flexDirection: "column-reverse",
        justifyItems: "center",
        alignItems: "center",
        gap: "2em",
        margin: "0 0 20px 0",

        [theme.breakpoints.up("md")]: {
            flexDirection: "row"
        }
    },
    feretLetterSubHeaderContent: {
        width: "100%",
        [theme.breakpoints.up("md")]: {
            width: "50%",
            flexShrink: "0",
        }
    },
    feretLetterSubHeaderContentTitle: {
        textAlign: "center",
        lineHeight: "42px",
        color: theme.palette.primary.main,
        [theme.breakpoints.up("md")]: {
            textAlign: "left",
            width: "100%",
        }
    },
    feretLetterSubHeaderImg: {
        width: "100%",
        margin: "0 auto",
        [theme.breakpoints.up("md")]: {
            width: "50%",
            minHeight: "100%"
        }

    },
    feretLetterTextHeaderContent: {
        fontSize: "20px",
        lineHeight: "28px", 
        textAlign: "justify",
        color: theme.palette.primary.main,
        [theme.breakpoints.up("md")]: {
            fontSize: "20px",
            lineHeight: "32px", 
        }
    },
    feretLetterTextContent: {
        fontSize: "18px",
        lineHeight: "28px", 
        textAlign: "justify",
        [theme.breakpoints.up("md")]: {
            fontSize: "20px",
            lineHeight: "32px", 
        },
        "& br": {
            display: "block",
            lineHeight: "30px"
        }
    },
    feretLetterTextTitleDescription: {
        fontSize: "18px",
        lineHeight: "28px", 
        textAlign: "justify", 
        margin: "1em 0 0 0",
    },
    separator: {
        width: "100%",
        height: "1px",
        backgroundColor: "#E2E2E2",
        border: "none",
        margin: "40px 0",
        [theme.breakpoints.up("md")]: {
            margin: "80px 0"
        }
    },
    imgWineFields: {
        width: "100%",
        [theme.breakpoints.up("md")]: {
            width: "75%",
            margin: "0 auto"
        }
    },
    testimonials: {
        padding: "0 0 4em 0"
    },
    testimonialWrapper: {

    },
    testimonialHeader: {
        position: "relative",
        height: "200px",
    },
    testimonialHeaderRight: {
        position: "relative",
        textAlign: "left",
        height: "200px",

        [theme.breakpoints.up("md")]: {
            textAlign: "right",
        }
    },
    testimonialHeaderTitle: {
        position: "absolute",
        left: "0",
        top: "50%",
        color: theme.palette.primary.main,
        transform: "translate(0,-50%)",
        [theme.breakpoints.up("md")]: {
        }
    },
    testimonialHeaderTitleRight: {
        position: "absolute",
        width: "100%",
        right: "0",
        top: "50%",
        color: theme.palette.primary.main,
        transform: "translate(0,-50%)",
        [theme.breakpoints.up("md")]: {
        }
    },
    testimonialHeaderImg: {
        position: "absolute",
        left: "0",
        top: "50%",
        zIndex: "-1",
        width: "100px",
        transform: "translate(40%,-40%) scale(1.75)",
        opacity: "0.4",
        
        [theme.breakpoints.up("lg")]: {
            transform: "translate(0,-40%) scale(1.75)",
        }
    },
    testimonialHeaderImgRight: {
        position: "absolute",
        right: "0",
        top: "50%",
        zIndex: "-1",
        width: "100px",
        transform: "translate(-40%,-40%) scale(-1.75, 1.75) ",
        opacity: "0.4",
        
        [theme.breakpoints.up("lg")]: {
            transform: "translate(0,-40%) scale(-1.75, 1.75)",
        }
    },
    tasterList: {
        display: "flex",
        flexDirection: "column",
        gap: "75px"
    },
    tasterWrapper: {
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
        textAlign: "justify",
        [theme.breakpoints.up("md")]: {
            flexDirection: "row",
            alignItems: "center",
            textAlign: "left",
        },
        gap: "30px"
    }, 
    tasterImg: {
        borderRadius: "50%",
        width: "150px",
        height: "150px",
        [theme.breakpoints.up("md")]: {
            width: "180px",
            height: "180px",
        },
        objectFit: "cover"
    },
    millesimeCTA: {
        padding: "40px 0", 
        display: "flex",
        flexDirection: "row",
        alignContent: "center",
        justifyContent: "center",
        background: "#EEE5E5",
    },
    millesimeBtn: {
        backgroundColor: "#915868",
        color: "#fff",
        padding: "10px 20px",
        fontFamily: "Playfair Display",
        textDecoration: "none",
        width: "fit-content",
        margin: "0 auto",
        borderRadius: "4px",
    },
    millesimeLink: {
        textDecoration: "none",
        fontFamily: "Playfair Display",
        width: "fit-content"
    },
    boxComment: { 
        position: "relative", 
        overflow: "hidden",
        padding: "0.5em", 
        border: "solid #632D3E 1px", 
        background: "FCF6F6", 
        textAlign: "justify",
        [theme.breakpoints.up("md")]: { 
            padding: "10px 100px 10px 10px" 
        } 
    },
    fioOne: {
        display: "none",
        [theme.breakpoints.up("md")]: {
            display: "block",
            position: "absolute", 
            right: "0", 
            top: "60px", 
            zIndex: "-1", 
            width: "400px",
        }
    },
    firstParagraph: {
        width: "100%",
        [theme.breakpoints.up("md")]: {
            width: "60%"
        }
    },
    millesimeBtnTypo: { 
        fontFamily: "Playfair Display", 
        fontWeight: "700", 
        fontSize: "18px",
        [theme.breakpoints.up("md")]: {
            fontSize: "22px",
        }
    },
    waveBox: { width:"100%", height: "60px", [theme.breakpoints.up("md")]: { height: "100px" }, position: "relative" }
}));

const FeretLetterPage = (props) => {
    const styles = useStyles(props, theme);
    const history = useCustomHistory();

    const redirection = () => {
        window.open("https://501d5642.sibforms.com/serve/MUIFAOiZvjoYMrT1PePLF1M2OpvyXtmN1QqULV3fpd-T86rBrGyo0vhnMrA4xjFUM3ui5rQ-GxgdqnQKzLRHHWVMsijnIIzmOK9JY3nnqg3TeRYR45EP0-_g1p9D39vlGaRRJxXRu5IG29lb70vuNQ-GB0mOO3e6EJTrSY9cHqfCsAdRONAle-P-gmw4Jhvwryl05Jp-otNj5uUj", "_blank");
    };

    const download = () => {
        window.open("https://feret.com/wp-content/uploads/2024/05/La-Lettre-Feret-n°1-1-1.pdf", "_blank");
    };

    const hashRefs = useRef({});
    const location = useLocation();

    const scrollToHash = () => {
        const hash = location.hash.slice(1);
        const element = hashRefs.current[hash];
        
        if (hash && element) {
            element.scrollIntoView({ behavior: "smooth" });
        }
    };

    useEffect(() => {
        setTimeout(() => {
            window.scrollTo(0, 0);
            scrollToHash(); 
        }, 0);
    }, [location]);

    // const isWindowLoaded = useRef(false);

    // const scrollToHash = () => {
    //     const hash = window.location.hash.slice(1);
    //     const element = hashRefs.current[hash];
        
    //     if (hash && element) {
    //         element.scrollIntoView({ behavior: "smooth", block: "start" });
    //     }
    // };

    // const handlePageLoad = () => {
    //     window.scrollTo(0, 0); // Scroll initial lors du chargement de la page
    //     scrollToHash(); // Scroll initial basé sur le hash
    // };

    // useEffect(() => {
    //     if (!isWindowLoaded.current) {
    //         handlePageLoad(); // Appel de la fonction pour le premier chargement
    //         isWindowLoaded.current = true; // Marquer que window.onload a été exécuté
    //     }

    //     const handlePopState = () => {
    //         scrollToHash(); // Scroll lors du changement d'URL (navigation en avant/arrière)
    //     };

    //     window.addEventListener('popstate', handlePopState);

    //     return () => {
    //         window.removeEventListener('popstate', handlePopState);
    //     };
    // }, []);

    // // Gestion du scroll initial avec window.onload si le chargement initial n'a pas encore été effectué
    // if (!isWindowLoaded.current) {
    //     window.onload = () => {
    //         handlePageLoad();
    //     };
    // }



    return (
        <>
            <Box
                display="flex"
                alignItems="center"
                gap={4}
                className={ styles.feretLetterHeader }
            >  
                <img src={ imgLetterFeretTxt } style={{ width: "300px", margin: "0 auto" }} onDragStart={ (e) => { e.preventDefault(); } } />
            </Box>

            <Box className={ styles.feretBodyContainer }>

                <Box className={ styles.feretLetterBody}>
                    <Typography style={{ margin: "1.5em 0 0 0", fontFamily: "dsds" }}>#1-mai 2024</Typography>
                    <Typography variant="h1">L&apos;actualité trimestrielle du vignoble bordelais vue par Féret.</Typography>
                </Box>

                <Box ref={ref => hashRefs.current["editorial"] = ref} className={ styles.feretLetterBody} style={{ padding: "0 0 25px 0", margin: "0 auto" }}>
                    <hr className={ styles.separator} style={{ margin: "40px 0 0 0" }} />
                </Box>

                {/* Éditorial de la Lettre Feret */}
                <Box className={ styles.feretLetterBody }>
                
                    <Box style={{ display: "flex", flexDirection: "column", gap: "0.5em" }}>
                        <Typography variant="h2" className={ styles.feretLetterSubHeaderContentTitle }>Éditorial</Typography>
                        <Typography variant="h3" className={ styles.feretLetterSubHeaderContentTitle }><i>À marquer d&apos;une pierre blanche</i></Typography>
                    </Box>

                    <img src={ fioOne} className={ styles.fioOne } />

                    <Typography variant="body1" className={ classNames(styles.feretLetterTextContent, styles.firstParagraph) }>
                        Un millésime tout en (belles) surprises comme le sera, nous l&apos;imaginons, l&apos;arrivée sur vos écrans de la « Lettre Féret ».   
                        <br/>
                        Tous les trimestres, cette « Lettre Féret » offrira une mine d&apos;informations précieuses aux amateurs et aux professionnels du vin, en fournissant des mises à jour sur les châteaux, les nouveaux millésimes ainsi que des analyses et des avis d&apos;experts. 
                    </Typography>

                    <Typography variant="body1" className={ styles.feretLetterTextContent }>
                        Cette « Lettre Féret » vous permettra d&apos;aller à la rencontre d&apos;actrices ou d&apos;acteurs de la filière et nous donnera l&apos;occasion de raconter ici vos initiatives ou de narrer quelques histoires. Pour cette première, Bénédicte Martre, directrice RSE-QHSE & Communication pour les Vignobles de Larose, est invitée à revenir sur les enjeux importants de la filière.  
                    </Typography>

                    <Typography paragraph variant="body1" className={ styles.feretLetterTextContent }>
                        Parce qu&apos;elle coïncide avec la fin de la campagne de dégustation des primeurs, cette lettre intègrera, et c&apos;est une première, la présentation des notes du millésime 2023. Pour cet événement annuel majeur, un collège d&apos;experts reconnus — composé de Bernard Grandchamp, d&apos;Étienne Khemtémourian  et de Gianni Degl&apos;Innocenti — livrera ses notations pour la maison Féret.
                        <br/>
                        Il fallait bien aussi une lettre d&apos;information pour vous annoncer la refonte de notre site et, nous en sommes très fiers, vous dire enfin que nous nous attelons désormais à la publication d&apos;une nouvelle édition de <i>Bordeaux et ses Vins</i>, la XXe. Le chemin sera long mais il était impensable de ne pas redonner naissance à cet emblématique bateau amiral de la grande maison d&apos;édition bordelaise.
                    </Typography>

                    <Box display="flex" flexDirection="row">
                        <img src={ imgWineFields } className={ styles.imgWineFields } />
                    </Box>

                    <Typography variant="body1" className={ styles.feretLetterTextContent }>
                        Vous l&apos;aurez compris, nous n&apos;avons pas conçu un énième objet de communication dont vos boîtes mails sont déjà largement pourvues. La « Lettre Féret », c&apos;est assumer la noblesse d&apos;un statut de boussole, c&apos;est encore imaginer que nous pouvons créer un objet hautement informatif. Il nous a semblé que nous avions toute légitimité à ressurgir auprès de vous comme un média et un acteur fédérateur.
                        <br/>
                        Avec cette Lettre, nous nous assignons la tâche d&apos;être la voix des actrices et des acteurs qui préservent le riche patrimoine viticole de la région. Bonne lecture à toutes et à tous.
                        <br/>
                        <br/>
                        Fidèlement vôtre, 
                        <br/>
                        <br/>
                        Henry Clemens
                        <br/>
                        Directeur de la publication
                    </Typography>

                    <Box style={{ padding: "0.5em", border: "solid #632D3E 1px", textAlign: "justify"  }}>
                        <Typography style={{  color: "#632D3E", fontWeight: "700", fontSize: "18px" }}>
                            Fondée en 1812 par Jean-Baptiste Féret à Bordeaux, la maison Féret est l&apos;une des plus anciennes maisons d&apos;édition spécialisées dans le domaine du vin en France dont <i>Bordeaux et ses Vins</i> constitua longtemps le véritable bateau amiral. Depuis sa création, elle s&apos;est engagée à promouvoir et à documenter l&apos;histoire et la culture viticoles, en publiant une variété d&apos;ouvrages de référence, d&apos;encyclopédies, de guides et de revues. Féret est réputée pour sa rigueur éditoriale et son engagement envers la qualité, ce qui en fait une source fiable d&apos;informations bicentenaires pour les professionnels, les passionnés de vin, les chercheurs ou les collectionneurs. Au fil des décennies, Féret a su établir sa réputation en tant qu&apos;autorité incontestée dans le monde du vin bordelais, contribuant ainsi à renforcer l&apos;image et la notoriété de la région viticole.
                        </Typography>
                    </Box>

                </Box>

                <hr ref={ref => hashRefs.current["notation-millesime"] = ref} className={ styles.separator } />

                {/* La note du millésime par Feret */}
                <Box className={ styles.feretLetterBody } style={{ marginBottom: "40px" }}>

                    <Box className={ styles.feretLetterSubHeader } >
                        <Box className={ styles.feretLetterSubHeaderContent }>

                            <Typography variant="h2" className={ styles.feretLetterSubHeaderContentTitle } style={{ margin: "0 0 1em 0", display: "inline-block", flexDirection: "row", alignContent: "center", gap: "0.5em" }}>La note du millésime par <img width="125" src={imgLogo} alt="" style={{ display: "inline-block" }}/></Typography>
                            <Typography variant="body1" className={ styles.feretLetterTextContent }>
                                Féret, c&apos;est une première, revêt l&apos;habit d&apos;un prescripteur passionné et légitime de plus de 200 ans d&apos;expertise avec la présentation de notes de dégustations primeurs. Pour cet événement annuel majeur, un collège d&apos;experts Féret reconnus (un jury trois étoiles !) livre les notations pour la campagne 2023.
                            </Typography>
                        </Box>

                        <img src={ imgWineDegustation } className={ styles.feretLetterSubHeaderImg } onDragStart={ (e) => { e.preventDefault(); } } />
                    </Box>

                    <Typography variant="h3" style={{ color: theme.palette.primary.main }}>Un millésime riche et multiple</Typography>

                    <Typography paragraph variant="body1" className={ styles.feretLetterTextContent }>
                        Il est bien entendu impossible de résumer le millésime 2023 à quelques caractéristiques ou séquences météorologiques. Bordeaux reste multiple, riche et démontre cette année encore sa forte capacité d&apos;adaptation. On parlera cette année une nouvelle fois d&apos;un millésime révélateur de lieux et de grands terroirs. 
                    </Typography>

                    <Typography variant="h4" className={ styles.feretLetterTextHeaderContent}>Un scénario à suspense</Typography>

                    <Typography paragraph variant="body1" className={ styles.feretLetterTextContent }>
                        Pour la campagne 2023, la fin de printemps est très humide, ce qui fait dire à Benjamin Massié de Derenoncourt Consultants, qu&apos;il s&apos;agit d&apos;un millésime qui a demandé une grande capacité d&apos;adaptation, aussi bien pour les bio que pour les conventionnels avec des cadences de traitements assez similaires.
                        <br/>
                        Le mois de juillet est marqué par un changement radical de climat. Cependant, les nuits restent chaudes avec une pression continue du mildiou. On a vite basculé à partir du 18 août dans une séquence caniculaire, et ce jusqu&apos;au 25 août. C&apos;est un millésime qui peut être vu comme un des plus chauds de la dernière décennie. Comparé à 2022, ce dernier laisse en revanche beaucoup plus parler les terroirs et peut, à certains égards, être comparé à 2014. 
                        <br/>
                        Phénomène plutôt rare, l&apos;été indien a permis la concomitance des maturités phénoliques et technologiques. Les arômes sont préservés en fin de cycle ce qui en fait un millésime très intéressant avec des équilibres aromatiques, de l&apos;énergie et de la fraîcheur
                    </Typography>

                    <Typography variant="h4" className={ styles.feretLetterTextHeaderContent}>Rendements et fraîcheur au rendez-vous</Typography>

                    <Typography paragraph variant="body1" className={ styles.feretLetterTextContent }>
                        On constate bien entendu une forte variété sur l&apos;ensemble du territoire, avec les impacts liés au mildiou ou au gel. On peut parler d&apos;un millésime de cabernets et plus particulièrement de cabernet sauvignon sur la rive gauche. Les rendements sont très beaux sur de nombreux secteurs.
                        <br/>
                        Les belles sorties de fruits ont souvent nécessité de soulager les vignes afin de pouvoir aller chercher les bonnes maturités. Il y a eu sur les sols plus filtrants quelques problèmes de stress hydrique. Sur le calcaire, on retrouve de belles acidités, de la fraîcheur, de l&apos;énergie et des jus souvent très mûrs.
                        <br/>                        
                        Il n&apos;est pas exagéré de dire qu&apos;en 2023, tout s&apos;est joué à la vigne avec, en particulier, le besoin de porter une attention particulière aux sols dès le mois d&apos;août. Avec toute une réflexion autour des couverts végétaux qu&apos;il fallait mener dès le mois de juin. En cuve, les équilibres sont intéressants avec une belle extractibilité des tanins et des arômes.
                        <br/>
                        Ce qui fait dire à beaucoup d&apos;œnologues qu&apos;il s&apos;agit en réalité d&apos;un millésime qui demande à s&apos;effacer. En fonction des terroirs, les jus ne devront pas être élevés de la même manière. Il faudra distinguer les lots issus de sables ou de graves aux bouquets aromatiques très fins et pas très opulents (d&apos;où une proportion accrue d&apos;amphores) ou encore les vins de sols argileux ou calcaires pour ne pas matraquer les jus. 
                    </Typography>

                    <Typography variant="h4" className={ styles.feretLetterTextHeaderContent}>Un millésime sans recette préétablie</Typography>

                    <Typography paragraph variant="body1" className={ styles.feretLetterTextContent }>
                        On repérera, bien souvent, dans ce millésime d&apos;« esthètes » les acteurs et actrices qui ont respecté la matière, à l&apos;extraction comme à l&apos;élevage.
                        <br/>
                        Des équilibres somme toute assez fragiles demanderont en effet parfois un peu de retenue. À l&apos;image des 2014 ou des 2016, le 2023 se présente comme une éponge à terroir. On peut encore une fois parler d&apos;un millésime de vignerons dans la mesure où 2023 a demandé une grande capacité d&apos;adaptation, de la flexibilité, la remise en cause de pratiques et une cuvaison particulièrement adaptée.
                    </Typography>

                    <Typography paragraph variant="body1" className={ styles.feretLetterTextContent }>
                        Les blancs offrent des équilibres parfaits. Les vins sont élégants et digestes avec des teneurs en alcool modérées. Les sauvignons blancs ont été ramassés précocement la dernière semaine d&apos;août. Ils affichent des notes suggérant les fruits jaunes, les fruits exotiques et sont souvent dénués de tout caractère végétal. Les sémillons plus tardifs ont échappé aux pluies de mi-septembre. Ils apportent du gras à l&apos;ensemble. Cela donne des vins aromatiques, complexes et joliment explosifs. 
                    </Typography>

                    <Typography paragraph variant="body1" className={ styles.feretLetterTextContent }>
                        Les rouges se présentent sous des atours plus fins qu&apos;opulents. Il faudra se montrer très attentif à ne pas trop extraire pour conserver le soyeux et la rondeur des débuts de fermentation. Finalement donc des vins tout en finesse, plus classiques mais d&apos;une jolie profondeur. Ils sont globalement construits sur une belle trame de tanins fins, procurée par une fin d&apos;été favorable et un beau début d&apos;automne. Les vins jouissent globalement de concentrations et de niveaux d&apos;alcool très modérés. Les grands terroirs sont au rendez-vous ! On se laissera surprendre par la richesse et la diversité de leurs profils.
                    </Typography>

                    <Box className={ styles.boxComment }>
                        <Typography style={{  color: "#632D3E", fontSize: "18px" }}>
                            La campagne 2023 fut intense et soutenue, dans la mesure où les sorties plus précoces se sont effectuées à un rythme très rapproché chez bon nombre de têtes d&apos;affiche et autres « stars », suscitant un peu d&apos;excitation. Cette campagne des primeurs a été plus ramassée et dense que 2022 qui s&apos;étira quant à elle sur deux mois. Comme rappelé par M. Bernard, du groupe Millésima, le contexte économique particulier d&apos;inflation et de ralentissement économique « génère une incertitude qui incite inéluctablement les consommateurs et les investisseurs à être plus prudents ». Sous entendant que les prix seront globalement revus à la baisse, aux vues du niveau des stocks chez les importateurs, les distributeurs et les cavistes ou encore de la baisse des ventes sur les principaux marchés exports. Ce qui, au regard de la qualité et du volume du millésime, devrait susciter un regain d&apos;attractivité et redonner le sourire aux consommateurs et aux producteurs.
                        </Typography>
                        <img src={ fioThree} style={{ position: "absolute", top: "-50px", right: "-10px", width: "250px", zIndex: "-1", opacity: "0.5" }} />
                    </Box>

                    <img src={ fioTwo } style={{ position: "absolute", left: "-300px", transform: "scale(-1.5, 1.5)", top: "10%", zIndex: "-1", width: "400px", opacity: "0.5" }}/>
                    <img src={ fioTwo } style={{ position: "absolute", right: "-300px", transform: "scale(1.5)", top: "30%", zIndex: "-1", width: "400px", opacity: "0.5" }}/>
                    <img src={ fioTwo } style={{ position: "absolute", left: "-300px", transform: "scale(-1.5, 1.5)", top: "50%", zIndex: "-1", width: "400px", opacity: "0.5" }}/>
                    <img src={ fioTwo } style={{ position: "absolute", right: "-300px", transform: "scale(1.5)", top: "70%", zIndex: "-1", width: "400px", opacity: "0.5" }}/>
                </Box>

                <Box className={ styles.waveBox }>
                    <img src={imgWave} style={{ height: "100%", width: "100%", position: "absolute", left: "0", right: "0", bottom:"0" }} />
                </Box>

                <Box ref={ref => hashRefs.current["tasters"] = ref} className={ styles.feretLetterBodyWithBgWrapper }>

                    <Box className={ styles.feretLetterBodyWithBg }>

                        <Box>
                            <Typography variant="h3" style={{ textAlign: "center", padding: "40px 0", fontSize: "28px" }}>Présentation de nos dégustateurs lors des primeurs 2023</Typography>
                        </Box>

                        <Box className={ styles.tasterList }>

                            <Box className={ styles.tasterWrapper }>
                                <Box>
                                    <img src={ imgTasterBernard } className={ styles.tasterImg } onDragStart={ (e) => { e.preventDefault(); } } />
                                </Box>

                                <Box>
                                    <Typography paragraph variant="h3" style={{ fontFamily: "Mansalva", color: theme.palette.primary.main }}>Bernard Grandchamp</Typography>
                                    <Typography paragraph variant="body1" className={ styles.feretLetterTextContent }>Bernard Grandchamp, ancien directeur de domaines viticoles et expert viticole à Bordeaux et ailleurs, dégustateur pour le Guide Hachette des Vins et divers concours internationaux, expert judiciaire près la cour d&apos;appel de Bordeaux de 1996 à 2006.</Typography>
                                </Box>
                            </Box>

                            <Box className={ styles.tasterWrapper }>
                                <Box>
                                    <img src={ imgTasterEtienne } className={ styles.tasterImg } onDragStart={ (e) => { e.preventDefault(); } } />
                                </Box>

                                <Box>
                                    <Typography paragraph variant="h3" style={{ fontFamily: "Mansalva", color: theme.palette.primary.main }}>Étienne Khemtémourian</Typography>
                                    <Typography paragraph variant="body1" className={ styles.feretLetterTextContent }>Étienne Khemtémourian, bloggeur et formateur à la dégustation, vinificateur à Montagne Saint-Émilion, grand connaisseur de Bordeaux, Bourgogne et Champagne, qui a collaboré autrefois à la RVF et au Gault & Millau.</Typography>
                                </Box>
                            </Box>

                            <Box className={ styles.tasterWrapper }>
                                <Box>
                                    <img src={ imgTasterGianni } className={ styles.tasterImg } onDragStart={ (e) => { e.preventDefault(); } } />
                                </Box>

                                <Box>
                                    <Typography paragraph variant="h3" style={{ fontFamily: "Mansalva", color: theme.palette.primary.main }}>Gianni Degl&apos;Innocenti</Typography>
                                    <Typography paragraph variant="body1" className={ styles.feretLetterTextContent }>Gianni Degl&apos;Innocenti : sommelier et dégustateur officiel de l&apos;<i>Associazione Italiana Sommelier</i> depuis 2004, rédacteur en chef et responsable des formations de troisième niveau. Également collectionneur et grand connaisseur des terroirs les plus prestigieux d&apos;Italie et de France, maître-dégustateur de l&apos;ONAF <i>(Organizzazione Nazionale Assaggiatori Formaggi).</i></Typography>
                                </Box>
                            </Box>

                            <Box className={ styles.tasterWrapper }>
                                <Box>
                                    <img src={ imgTasterHenry } className={ styles.tasterImg } onDragStart={ (e) => { e.preventDefault(); } } />
                                </Box>

                                <Box>
                                    <Typography paragraph variant="h3" style={{ fontFamily: "Mansalva", color: theme.palette.primary.main }}>Henry Clemens</Typography>
                                    <Typography paragraph variant="body1" className={ styles.feretLetterTextContent }>Après un long parcours en tant que formateur, enseignant et rédacteur vin, il intègre le milieu de la presse. Rédacteur régulier pour les revues spécialisées <i>Sommeliers International</i>, <i>Tanin</i>, <i>Terre de Vins</i> ou encore la revue culturelle <i>JunkPage</i>, dont il fut un temps rédacteur en chef, il a rejoint la maison d&apos;édition Féret en 2024 au poste de Directeur des Opérations.</Typography>
                                </Box>
                            </Box>

                        </Box>

                    </Box>

                </Box>

                <Box style={{ position: "relative", width:"100%" }}>
                    <Box className={ styles.millesimeCTA }>
                        <Link to={ history.addLocale("/note-du-millesime") } className={ styles.millesimeLink }><Button color="primary" variant="contained" size="large" className={styles.millesimeBtn }><Typography className={ styles.millesimeBtnTypo }>Consulter les notes du millésime 2023.</Typography></Button></Link>
                    </Box>    
                
                    <img src={imgWave} style={{ height: "100%", width: "100%", transform: "rotate(180deg) translate(0, -100%)", position: "absolute", left: "0", right: "0", bottom:"0" }}/>
                </Box>

                <Box className={ styles.feretLetterBody }>
                    <Box style={{ margin: "0 auto", maxWidth: "550px", textAlign: "center", display: "flex", flexDirection: "column", alignContent: "center", justifyContent: "center", gap: "10px" }}>
                        <img src={ imgTasters } style={{ width: "100%", margin: "0 auto", maxWidth: "950px" }} />
                        <Typography style={{ fontSize: "14px" }}><i>Étienne Khemtémourian, Gianni Degl&apos;Innocenti, Bernard Grandchamp, dégustateurs Féret, Lucille Rioual et Claudia Campanella, accompagnatrices et interprètes pour nos dégustateurs.</i></Typography>
                    </Box>
                </Box>

                <hr className={ styles.separator } />

                {/* Coup de projecteur sur : Bénédicte Martre */}

                <Box ref={ ref => hashRefs.current["benedicte-martre"] = ref } className={ styles.feretLetterBodyWithWrapper }>

                    <img src={ fioFour } style={{ position: "absolute", top: "20px", transform: "scale(1.5)", left: "0px", width: "250px", zIndex: "-1", opacity: "0.5" }} />
                    
                    <Box className={ styles.feretLetterBody }>

                        <img src={ fioTwo } style={{ position: "absolute", right: "-300px", transform: "scale(1.5)", top: "10%", zIndex: "-1", width: "400px", opacity: "0.5" }}/>
                        <img src={ fioTwo } style={{ position: "absolute", left: "-300px", transform: "scale(-1.5, 1.5)", top: "20%", zIndex: "-1", width: "400px", opacity: "0.5" }}/>
                        <img src={ fioTwo } style={{ position: "absolute", right: "-300px", transform: "scale(1.5)", top: "30%", zIndex: "-1", width: "400px", opacity: "0.5" }}/>
                        <img src={ fioTwo } style={{ position: "absolute", left: "-300px", transform: "scale(-1.5, 1.5)", top: "40%", zIndex: "-1", width: "400px", opacity: "0.5" }}/>
                        <img src={ fioTwo } style={{ position: "absolute", right: "-300px", transform: "scale(1.5)", top: "50%", zIndex: "-1", width: "400px", opacity: "0.5" }}/>
                        <img src={ fioTwo } style={{ position: "absolute", left: "-300px", transform: "scale(-1.5, 1.5)", top: "60%", zIndex: "-1", width: "400px", opacity: "0.5" }}/>
                        <img src={ fioTwo } style={{ position: "absolute", right: "-300px", transform: "scale(1.5)", top: "70%", zIndex: "-1", width: "400px", opacity: "0.5" }}/>
                        <img src={ fioTwo } style={{ position: "absolute", left: "-300px", transform: "scale(-1.5, 1.5)", top: "80%", zIndex: "-1", width: "400px", opacity: "0.5" }}/>
                        <img src={ fioFive } style={{ position: "absolute", right: "-300px", transform: "scale(-1, 1)", bottom: "0", zIndex: "-1", width: "400px", opacity: "0.5" }}/>

                        <Box className={ styles.feretLetterSubHeader } style={{ padding: "70px 0 0 0" }}>
                            <Box className={ styles.feretLetterSubHeaderContent } justifyContent="center" style={{ gap: "1em" }}>
                                <Typography variant="h2" className={ styles.feretLetterSubHeaderContentTitle }>Coup de projecteur sur : <i>Bénédicte Martre</i></Typography>
                                <Typography variant="body1" className={ styles.feretLetterTextTitleDescription }>Féret part à la rencontre de Bénédicte Martre, directrice RSE-QHSE & Communication des Vignobles de Larose</Typography>
                                <Typography variant="body1" className={ styles.feretLetterTextTitleDescription }><i>« Nous n&apos;avons qu&apos;une seule feuille de route stratégique, et c&apos;est la RSE qui est notre boussole »</i></Typography>
                            </Box>

                            <img src={imgBenedicteMartre} className={ styles.feretLetterSubHeaderImg } onDragStart={ (e) => { e.preventDefault(); } } />

                        </Box>

                        <Box className={styles.testimonials}>

                            {/* Left */}
                            <Box className={styles.testimonialWrapper}>
                                <Box className={styles.testimonialHeader} >
                                    <Typography variant="h3" className={ styles.testimonialHeaderTitle }>Qui êtes-vous ?</Typography>
                                    <img className={ styles.testimonialHeaderImg }  alt="" src={ imgFeretBubble } />
                                </Box>

                                <Typography paragraph variant="body1" className={ styles.feretLetterTextContent }>
                                    Je suis bordelaise d&apos;origine. J&apos;ai exercé au départ des responsabilités dans le domaine de la communication et du développement commercial en France et à l&apos;étranger essentiellement dans l&apos;agro-alimentaire. 
                                </Typography>

                                <Typography paragraph variant="body1" className={ styles.feretLetterTextContent }>
                                    Puis à l&apos;âge de trente ans, je suis arrivée dans le vin par amour de ce produit. J&apos;ai toujours été passionnée par le vin. À 20 ans, je prenais des cours de dégustation en parallèle de mes études de commerce. 
                                </Typography>
                                    
                                <Typography paragraph variant="body1" className={ styles.feretLetterTextContent }>
                                    J&apos;ai fait d&apos;un loisir personnel le fil rouge de ma vie professionnelle. Le vin est le secteur dans lequel j&apos;évolue depuis plus de 20 ans.
                                </Typography>
                            </Box>

                            {/* Right */}
                            <Box className={styles.testimonialWrapper}>
                                <Box className={styles.testimonialHeaderRight} >
                                    <Typography variant="h3" className={ styles.testimonialHeaderTitleRight } >De l&apos;agro-alimentaire à la RSE...</Typography>
                                    <img className={ styles.testimonialHeaderImgRight }  alt="" src={ imgFeretBubble } />
                                </Box>

                                <Typography paragraph variant="body1" className={ styles.feretLetterTextContent }>
                                    J&apos;ai acquis une expérience très globale, de la production à la commercialisation et à la communication en passant par la dégustation professionnelle. De cette expérience transverse, j&apos;ai acquis la conviction, il y a une dizaine d&apos;années, que la filière allait affronter des enjeux aussi bien environnementaux qu&apos;économiques ou sociétaux.
                                </Typography>
                                <Typography paragraph variant="body1" className={ styles.feretLetterTextContent }>
                                    J&apos;étais persuadée que la RSE serait la grille de lecture qui permettrait aux entreprises viticoles d&apos;appréhender ces enjeux. Je me suis donc spécialisée en RSE et en développement durable, comme consultante indépendante et également évaluatrice ISO 26000 pour l&apos;AFNOR.                          
                                </Typography>
                                <Typography paragraph variant="body1" className={ styles.feretLetterTextContent }>
                                    En 2022, les Vignobles de Larose m&apos;ont proposé de prendre la direction d&apos;un pôle RSE et QHSE, élargi à la communication car se pose également la question de la création de valeur autour de nos engagements.
                                </Typography>
                            </Box>

                            {/* Left */}
                            <Box className={styles.testimonialWrapper}>
                                <Box className={styles.testimonialHeader} >
                                    <Typography variant="h3" className={ styles.testimonialHeaderTitle }>Parlez-nous des Vignobles de Larose</Typography>
                                    <img className={ styles.testimonialHeaderImg }  alt="" src={ imgFeretBubble } />
                                </Box>

                                <Typography paragraph variant="body1" className={ styles.feretLetterTextContent }>
                                    Ce sont quatre propriétés, quatre crus bourgeois du Médoc appartenant au groupe d&apos;assurance Allianz : Château Larose-Trintaudon, Château Larose Perganson, Château Arnauld et Château Tour de Pez. Pesant 260 hectares, les Vignobles de Larose sont un acteur historique du Médoc.
                                </Typography>

                                <Typography paragraph variant="body1" className={ styles.feretLetterTextContent }>
                                    Ils sont pionniers de l&apos;innovation et de l&apos;engagement responsable dans la filière vin : 1er vignoble français à obtenir la double certification ISO 9001 & 14001 en 2003 et 1er vignoble européen, 1re entreprise d&apos;Aquitaine à obtenir le Label Engagé RSE de l&apos;AFNOR en 2008.                             
                                </Typography>
                            </Box>

                            {/* Right */}
                            <Box className={styles.testimonialWrapper}>
                                <Box className={styles.testimonialHeaderRight} >
                                    <Typography variant="h3" className={ styles.testimonialHeaderTitleRight }>Un passif parfait pour vous ?</Typography>
                                    <img className={ styles.testimonialHeaderImgRight }  alt="" src={ imgFeretBubble } />
                                </Box>

                                <Typography paragraph variant="body1" className={ styles.feretLetterTextContent }>
                                    Oui, il était important que je rejoigne des propriétés réellement investies dans une démarche RSE. Nous n&apos;avons qu&apos;une seule feuille de route stratégique, et c&apos;est la RSE qui est notre boussole. Le pôle RSE est un pôle à part entière placé sous l&apos;autorité directe du directeur général, Franck Bijon. 
                                </Typography>
                                <Typography paragraph variant="body1" className={ styles.feretLetterTextContent }>
                                    Par ailleurs, les Vignobles de Larose ont, dès le début, chercher à faire évaluer leur démarche RSE par des organismes tiers. L&apos;amélioration continue et les preuves sont au cœur de nos réflexions.
                                </Typography>
                            </Box>

                            {/* Left */}
                            <Box className={styles.testimonialWrapper}>
                                <Box className={styles.testimonialHeader} >
                                    <Typography variant="h3" className={ styles.testimonialHeaderTitle }>Comment se réinventer lorsque l&apos;on est à Bordeaux ?</Typography>
                                    <img className={ styles.testimonialHeaderImg }  alt="" src={ imgFeretBubble } />
                                </Box>

                                <Typography paragraph variant="body1" className={ styles.feretLetterTextContent }>
                                    J&apos;ai le sentiment que Bordeaux passe son temps à se réinventer. C&apos;est un vignoble en mouvement permanent. Nous disposons d&apos;un écosystème très riche et stimulant avec beaucoup d&apos;expertise dans tous les domaines : éducation, cluster d&apos;innovation, interprofession, syndicats, distributeurs, fournisseurs, vignerons.                                                          
                                </Typography>
                                
                                <Typography paragraph variant="body1" className={ styles.feretLetterTextContent }>
                                    Nous nous réinventons sans arrêt. Nous avons encore beaucoup d&apos;enjeux à affronter mais nous disposons de nombreux atouts pour cela. Il me semble que nous avons notamment des marges de progression en termes de communication et de marketing du vin : travailler l&apos;image du vin auprès des jeunes consommateurs ; travailler la question des formats ; rendre nos vins désirables ; conquérir les lieux de consommation festifs, sportifs ou encore les festivals. 
                                </Typography>
                            </Box>

                            {/* Right */}
                            <Box className={styles.testimonialWrapper}>
                                <Box className={styles.testimonialHeaderRight} >
                                    <Typography variant="h3" className={ styles.testimonialHeaderTitleRight }>Est-ce que votre groupe répond aux enjeux sociaux et RH ?</Typography>
                                    <img className={ styles.testimonialHeaderImgRight }  alt="" src={ imgFeretBubble } />
                                </Box>

                                <Typography paragraph variant="body1" className={ styles.feretLetterTextContent }>
                                    Entre autres bonnes pratiques, les Vignobles de Larose ont été les premiers à mettre en place des activités physiques adaptées dès 2015 ! Nous avons établi un partenariat avec une société nommée Opti&apos;Mouv, fondée par Romain Balaguier. En 2013, Romain, encore étudiant, a soutenu son mémoire de Master STAPS sur le sujet de la prévention des troubles musculo-squelettiques (TMS) grâce à de l&apos;activité physique adaptée.
                                </Typography>
                                <Typography paragraph variant="body1" className={ styles.feretLetterTextContent }>
                                    Nous avons servi de cobaye. Nous disposons désormais d&apos;une salle dédiée à la pratique sportive pour des sessions encadrées par un coach d&apos;Opti&apos;Mouv ! Outre la réduction des TMS, ce partenariat est aussi un outil de cohésion formidable.
                                </Typography>
                            </Box>

                            {/* Left */}
                            <Box className={styles.testimonialWrapper}>
                                <Box className={styles.testimonialHeader} >
                                    <Typography variant="h3" className={ styles.testimonialHeaderTitle }>Parlez-moi du millésime 2023 ?</Typography>
                                    <img className={ styles.testimonialHeaderImg }  alt="" src={ imgFeretBubble } />
                                </Box>

                                <Typography paragraph variant="body1" className={ styles.feretLetterTextContent }>
                                    C&apos;est une saison viticole où nous avons connu une saison estivale chaude et longue de plus de 4 mois et des précipitations régulières malgré un léger déficit. On retrouve la fraîcheur aromatique de 2019 et la générosité de la nature de 2015 ou 2016.                          
                                </Typography>
                                <Typography paragraph variant="body1" className={ styles.feretLetterTextContent }>
                                    Nos vins possèdent des profils en phase avec les attentes des consommateurs avec beaucoup de fraicheur, du fruit et des degrés alcooliques maîtrisés. Nous sommes très heureux de ce que nous avons présenté pour les primeurs !
                                </Typography>
                            </Box>

                            {/* Right */}
                            <Box className={styles.testimonialWrapper}>
                                <Box className={styles.testimonialHeaderRight} >
                                    <Typography variant="h3" className={ styles.testimonialHeaderTitleRight }>Avez-vous une actualité dont vous aimeriez nous parler ?</Typography>
                                    <img className={ styles.testimonialHeaderImgRight }  alt="" src={ imgFeretBubble } />
                                </Box>

                                <Typography paragraph variant="body1" className={ styles.feretLetterTextContent }>
                                    2024 est une grande première. En parallèle de notre objectif de réduction de nos émissions de gaz à effet de serre, nous recherchions depuis 2021 une solution pour mesurer et optimiser notre séquestration de carbone. Grâce au cluster d&apos;innovation Inno&apos;vin, nous avons identifié NetCarbon, une start-up soutenue par l&apos;Agence spatiale européenne et multi lauréate du prix Challenge startups d&apos;AIRBUS, du concours Copernicus Masters (pour la version française portée par le CNES) et du concours Planet (startup d&apos;observation de la Terre aux États-Unis).
                                </Typography>
                                <Typography paragraph variant="body1" className={ styles.feretLetterTextContent }>
                                    Grâce à la solution proposée par NetCarbon, nous sommes pour la première fois en capacité de calculer notre captation du carbone. En lien avec les pratiques agro-écologiques mises en place sur nos vignobles, nous pouvons constater une augmentation de la séquestration carbone de 5 % par an depuis 2019. Cet outil nous permet également de faire des simulations et de construire ainsi de manière pertinente notre trajectoire carbone.
                                </Typography>
                            </Box>

                            {/* Left */}
                            <Box className={styles.testimonialWrapper}>
                                <Box className={styles.testimonialHeader} >
                                    <Typography variant="h3" className={ styles.testimonialHeaderTitle }>Si vous étiez un vin ?</Typography>
                                    <img className={ styles.testimonialHeaderImg }  alt="" src={ imgFeretBubble } />
                                </Box>

                                <Typography variant="body1" className={ styles.feretLetterTextContent }>
                                    Je dirais Larose-Trintaudon. Au-delà de l&apos;excellente qualité et de son étiquette rouge très reconnaissable, c&apos;est une marque qui dispose d&apos;un gros capital sympathie. Je suis touchée par les histoires que racontent les clients qui viennent nous visiter et goûter nos vins. C&apos;est un vin avec une cote d&apos;amour impressionnante et particulière. Nous faisons aussi du vin pour être aimé. <i>(Rires.)</i>
                                </Typography>
                            </Box>

                            <Box style={{ padding: "60px 0 0 0", display: "flex", flexDirection: "row", alignContent: "center", justifyContent: "center", gap: "1.5em"}}>
                                <Button onClick={ () => { download(); } } color="primary" variant="contained" size="large" style={{ fontFamily: "Playfair Display" }}>Télécharger</Button>
                                <Button onClick={ () => { redirection(); } }  color="secondary" variant="contained" size="large" style={{ fontFamily: "Playfair Display" }}>S&apos;abonner</Button>
                            </Box>

                        </Box>

                    </Box>


                </Box>

            </Box>

        </>
    );
};

export default FeretLetterPage;
