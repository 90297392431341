// @ts-ignore
import ReCaptcha from "react-google-recaptcha";
import { makeStyles } from "@material-ui/styles";
import { FormEvent, ReactElement, useState } from "react";
import { JSXElement } from "@babel/types";
import { Box } from "@material-ui/core";
import { toast } from "react-toastify";
import cn from "classnames";

import { useHandleReCaptchaMutation } from "generated/graphql";
import { RE_CAPTCHA_KEY } from "config/constants";

interface StyleType {
    form: object;
    recaptcha: object;
    button: object;
}

type positionType = "top" | "bottom";

const useStyle = makeStyles(() => ({
    form: {
        width: "100%",
    },
}));

interface ReCaptchFormProps {
    children: JSXElement | JSXElement[];
    style: StyleType;
    position: positionType;
    onSubmit: Function;
    submitButton: (challengeCompleted: boolean) => JSXElement;
    className: string;
    buttonClassName: string;
    recaptchaClassName: string;
}

interface RecaptchaComponentProps {
    onChange: Function;
    className: string;
    style: object;
}

const RecaptchaComponent = ({ onChange, className, style }: RecaptchaComponentProps) => (
    <Box id="recaptcha-container" className={className} style={style}>
        <ReCaptcha sitekey={RE_CAPTCHA_KEY} onChange={onChange} />
    </Box>
);

export const ReCaptchaForm = ({
    children,
    style,
    position = "bottom",
    onSubmit,
    submitButton,
    className,
    buttonClassName,
    recaptchaClassName,
}: ReCaptchFormProps): ReactElement => {
    const styles = useStyle({ style, position });
    const [challengeCompleted, setChallengeCompleted] = useState(false);

    const [handleReCaptchaMutation] = useHandleReCaptchaMutation();

    const verifyCallback = async (response: string) => {
        if (response) {
            try {
                await handleReCaptchaMutation({ variables: { recaptchaResponse: response } });
                setChallengeCompleted(true);
                return;
            } catch (error) {
                toast.error("La validation du captcha a échoué, veuillez recommencer");
            }
        }
        setChallengeCompleted(false);
    };

    const handleSubmit = (event: FormEvent) => {
        event.preventDefault();
        if (challengeCompleted) {
            onSubmit();
        }
    };

    return (
        <form className={cn(styles.form, className)} onSubmit={handleSubmit} style={style?.form}>
            {position === "top" && (
                <RecaptchaComponent onChange={verifyCallback} className={recaptchaClassName} style={style?.recaptcha} />
            )}
            {children}
            {position !== "top" && (
                <RecaptchaComponent onChange={verifyCallback} className={recaptchaClassName} style={style?.recaptcha} />
            )}
            <Box className={buttonClassName} style={style?.button}>
                {submitButton && submitButton(challengeCompleted)}
            </Box>
        </form>
    );
};
