import { t } from "@lingui/macro";
import { Box, Button, Card, ClickAwayListener, Icon, makeStyles, Slide, SvgIcon, Typography } from "@material-ui/core";
import classNames from "classnames";
import { array, bool, elementType, string } from "prop-types";
import { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import { NavbarContext } from "Routes/MainRouter";

import { FR_AND_EN, SUBSCRIPTION_TYPES } from "config/constants";
import BrowsingPreferenceModal from "Front/browsingPreference/BrowsingPreferenceModal";
import { useCustomHistory } from "hooks";
import { useTranslation } from "react-i18next";
import viewEstate from "../../assets/eye-open.svg";
import account from "../../assets/navbarfront/account.svg";
import arrow from "../../assets/navbarfront/arrow_search.svg";
import { ReactComponent as MenuIcon } from "../../assets/navbarfront/burger.svg";
import fioriture from "../../assets/navbarfront/fioriture.svg";
import ftImage from "../../assets/navbarfront/ft.svg";
import inputSearchIcon from "../../assets/navbarfront/inputLoupe.svg";
import journalistImage from "../../assets/navbarfront/journalistImage.svg";
import logout from "../../assets/navbarfront/logout_front.svg";
import manage from "../../assets/navbarfront/manage_infos.svg";
import searchIcon from "../../assets/navbarfront/newLoupe.svg";
import LanguageSelector from "../../Components/Accordions/LanguageSelector";
import { signOut } from "../../features/user/userSlice";
import theme from "../../globalTheme";
import {
    darkColorSvg,
    formatPerson,
    getPaymentStatus,
    menuCTA,
    urlFormatted,
    useWindowDimensions,
} from "../../helpers/helpers";
import AutocompleteSearch from "../../Pages/SearchEngine/AutocompleteSearch";
import Account from "./Account";
import DiscoverFt from "./DiscoverFt";
import Menu from "./Menu";
import NavbarMenuList from "./NavbarMenuList";
import SubscriptionInProgress from "./SubscriptionInProgress";

const useStyles = makeStyles((theme) => ({
    navbarContainer: (args) => {
        return {
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.08)",
            zIndex: 11,
            top: "0",
            left: "0",
            background: args.scroll ? theme.palette.common.white : (args.opaque) ? "#360318" :  "rgba(0,0,0,0.3)",
            transition: "background 0.5s",
            justifyContent: "space-between",
            display: "flex",
            alignItems: "center",
            width: "100%",
            position: "sticky",
            height: "50px",
            [theme.breakpoints.up("md")]: {
                height: "75px",
            },
        };
    },
    fioriture: (args) => {
        return {
            display: "none",
            filter: args.scroll && darkColorSvg,
            [theme.breakpoints.up("xl")]: {
                display: "flex",
                width: "auto",
                height: "auto",
            },
        };
    },
    logo: (args) => {
        return {
            cursor: "pointer",
            width: "auto",
            height: "auto",
            filter: args.scroll && darkColorSvg,
            [theme.breakpoints.up("xs")]: {
                display: "none",
            },
            [theme.breakpoints.up("sm")]: {
                display: "none",
            },
            [theme.breakpoints.up("md")]: {
                marginLeft: "1rem",
                marginRight: 0,
                display: "flex",
            },
            [theme.breakpoints.up("lg")]: {
                width: "auto",
            },
            [theme.breakpoints.up("xl")]: {
                marginLeft: "0",
                width: "auto",
            },
        };
    },
    logoStandalone: (args) => {
        return {
            cursor: "pointer",
            width: "auto",
            height: "auto",
            filter: args.scroll && darkColorSvg,
            [theme.breakpoints.up("xs")]: {
                display: "none",
            },
            [theme.breakpoints.up("sm")]: {
                display: "inherit",
            },
            [theme.breakpoints.up("md")]: {
                display: "none",
            },
        };
    },
    imgLogo: {
        color: theme.palette.common.white,
        display: "flex",
        flex: 1,
        [theme.breakpoints.up("xs")]: {
            height: "100%",
            width: "100%",
        },
        [theme.breakpoints.up("md")]: {
            height: "80%",
            width: "80%",
        },
        [theme.breakpoints.up("lg")]: {
            width: "100%",
            height: "100%",
        },
        [theme.breakpoints.up("xl")]: {
            width: "auto",
            height: "auto",
        },
    },
    search: {
        [theme.breakpoints.down("sm")]: {
            display: "none",
        },
        [theme.breakpoints.up("md")]: {
            display: "none",
            margin: "unset",
        },
        [theme.breakpoints.up("lg")]: {
            display: "flex",
            alignItems: "center",
            width: "191px",
        },
    },
    responsiveIconSearch: {
        width: "auto",
        height: "auto",
        [theme.breakpoints.up("xs")]: {
            display: "flex",
        },
        [theme.breakpoints.up("lg")]: {
            display: "none",
        },
    },
    responsiveSearch: {
        display: "flex",
        flex: "1",
        justifyContent: "flex-start",
        alignItems: "center",
        margin: "0 1rem",
    },
    account: {
        width: "auto",
        height: "auto",
        [theme.breakpoints.up("xs")]: {
            margin: "1rem",
        },
        [theme.breakpoints.up("sm")]: {
            marginRight: "6px",
        },
    },
    button: {
        display: "flex",
        alignItems: "center",
        [theme.breakpoints.down("sm")]: {
            display: "none",
        },
        [theme.breakpoints.up("md")]: {
            width: "170px",
            marginLeft: "1rem",
            // padding: "0.875rem 0",
        },
        [theme.breakpoints.up("lg")]: {
            width: "170px",
            marginLeft: "0",
            // padding: "0.875rem 0",
        },
        [theme.breakpoints.up("xl")]: {
            width: "auto",
            marginLeft: "0",
            padding: "0",
        },
    },
    containedPrimary: {
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.08)",
        backgroundColor: theme.palette.primary.dark,
        border: `1px solid ${theme.palette.primary.dark}`,
        color: theme.palette.common.white,
        "&:hover": {
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.08)",
            background: theme.palette.common.white,
            color: theme.palette.primary.dark,
        },
    },
    contentBugerIcon: {
        display: "flex",
        alignItems: "center",
        marginLeft: "1rem",
        [theme.breakpoints.up("md")]: {
            display: "none",
        },
    },
    burgerIcon: (args) => {
        return {
            cursor: "pointer",
            width: "2rem",
            stroke: args.scroll ? theme.palette.primary.dark : theme.palette.common.white,
        };
    },
    containerRight: {
        [theme.breakpoints.up("xs")]: {
            display: "flex",
            marginRight: "1rem",
            alignItems: "center",
        },
        [theme.breakpoints.up("md")]: {
            margin: "0 1rem",
        },
        [theme.breakpoints.up("xl")]: {
            marginRight: "3rem",
        },
    },
    container_connected: {
        position: "absolute",
        top: "100%",
        width: "15rem",
        right: "0",
        borderTopLeftRadius: "0",
        borderTopRightRadius: "0",
        borderBottomLeftRadius: "20px",
        borderBottomRightRadius: "20px",

        padding: "1rem",
    },
    text_connected: {
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
        width: "fit-content",
        textDecoration: "none",
    },
    icons_connected: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "20px",
        marginRight: "0.5rem",
    },
    connectedDot: {
        width: "4px",
        height: "4px",
        backgroundColor: theme.palette.success.dark,
        top: "0px",
        right: "2px",
        display: "flex",
        position: "absolute",
        borderRadius: "50%",
    },
    autocompleteContainer: {
        width: "100%",
        borderRadius: "50px",
    },
    autocompleteInput: {
        fontStyle: "italic",
        fontSize: "14px",
        color: theme.palette.primary.dark,
    },
    autocompletePaper: {
        marginTop: 16,
        borderRadius: 10,
    },
    actionButton: {
        fontFamily: "Mansalva",
        textAlign: "center",
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        lineHeight: "17px",
        [theme.breakpoints.up("xl")]: {
            flexDirection: "column",
        },
    },
}));

const Navbar = (props) => {
    const settingsLocale = useSelector((state) => state?.user?.locale || "fr");
    const location = useLocation();
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [search, setSearch] = useState("");
    const [displaySearch, setDisplaySearch] = useState(false);
    const [showAccount, setShowAccount] = useState(false);
    const [showMenu, setShowMenu] = useState(false);
    const [showConnected, setShowConnected] = useState(false);
    const [scroll, setScroll] = useState(false);
    const [overflow, setOverflow] = useState();
    const [opaque, setOpaque] = useState(false);
    const styles = useStyles({ scroll, showMenu, opaque });
    const currentUser = useSelector((state) => state.user.currentUser);
    const paymentStatus = useSelector((state) => getPaymentStatus(state));
    const { width } = useWindowDimensions();
    const history = useCustomHistory();
    const dispatch = useDispatch();
    const navbarRef = useRef();
    const { openConnectionMenu, setOpenConnectionMenu, ftId } = useContext(NavbarContext) ?? {};
    const { t: ti18next } = useTranslation();
    const [warningBrowserLanguage, setWarningBrowserLanguage] = useState(false);

    useEffect(() => {
        if(!scroll) {            
            switch(location.pathname) {
                // case history.addLocale("/note-du-millesime"):
                case history.addLocale("/la-lettre-feret-sommaire"):
                case history.addLocale("/la-lettre-feret"):
                case history.addLocale("/recherche"):
                case history.addLocale("/"):
                    setOpaque(true);
                    break;
            }
        }
    });

    useEffect(() => {
        const onScroll = () => {
            const scroll = document.documentElement.scrollTop;
            if (navbarRef.current) {
                if (scroll > 0) {
                    setScroll(true);
                } else {
                    setScroll(false);
                }
            }
        };

        window.addEventListener("scroll", onScroll);
        return () => window.removeEventListener("scroll", onScroll);
    });

    useEffect(() => {
        if (currentUser?.subscriptionName === SUBSCRIPTION_TYPES.journalist || !currentUser) {
            setWarningBrowserLanguage(!FR_AND_EN.includes(settingsLocale));
        } else {
            setWarningBrowserLanguage(settingsLocale !== "fr");
        }
    }, [settingsLocale, showConnected]);

    const handleListItemClick = (event, index) => {
        setSelectedIndex(index);
    };

    const handleSignOut = () => {
        dispatch(signOut());
        setShowConnected(false);
    };

    useEffect(() => {
        const hidden = setTimeout(() => setOverflow(false), 300);
        return () => {
            clearTimeout(hidden);
        };
    }, [overflow]);

    return (
        <Box className={classNames(styles.navbarContainer, props.classNameNavbar)} ref={navbarRef}>
            <Slide timeout={400} direction="bottom" in={showMenu} mountOnEnter unmountOnExit>
                <Menu
                    loadingMenuItems={props.loadingMenuItems}
                    buttonBESV={props.buttonBESV}
                    search={props.search}
                    currentUser={currentUser}
                    onClose={() => setShowMenu(false)}
                    open={showMenu}
                    datas={props.datas}
                    accountOnClick={() => setShowAccount(true)}
                    logo={props.logo}
                    standalone={props.standalone}
                />
            </Slide>
            <Slide timeout={400} direction="left" in={showAccount} mountOnEnter unmountOnExit>
                <Account onClose={() => setShowAccount(false)} currentUser={currentUser} />
            </Slide>
            <Slide timeout={400} direction="left" in={openConnectionMenu} mountOnEnter unmountOnExit>
                <DiscoverFt
                    ftId={ftId}
                    onClose={() => setOpenConnectionMenu(false)}
                    open={true}
                    currentUser={currentUser}
                    onOpen={() => setOpenConnectionMenu(true)}
                />
            </Slide>

            <Icon className={styles.fioriture}>
                {props.fioriture ? <img src={fioriture} /> : <span style={{ width: "2rem" }} />}
            </Icon>
            <Icon className={classNames(styles.logo, props.classNameLogo)} component={Link} to={props.hrefLogo}>
                <Box className={styles.imgLogo}>{props.logo}</Box>
            </Icon>
            <div
                className={props.classNameSlideContent}
                id="slide-contents"
                style={{ display: displaySearch ? "none" : "flex", overflow: overflow ? "hidden" : "visible" }}
            >
                <Slide timeout={300} direction="up" in={!displaySearch} mountOnEnter unmountOnExit>
                    <Box
                        style={{
                            display: "flex",
                            flex: "1",
                            justifyContent: width >= 960 ? "space-around" : "space-between",
                            top: "100%",
                            alignItems: "center",
                            height: width < 960 ? "50px" : "inherit",
                        }}
                    >
                        <Box className={styles.contentBugerIcon}>
                            <SvgIcon color="primary" className={styles.burgerIcon} onClick={() => setShowMenu(true)}>
                                <MenuIcon
                                    style={{
                                        width: "3rem",
                                        height: "fit-content",
                                        fill: scroll && theme.palette.primary.dark,
                                    }}
                                />
                            </SvgIcon>
                            {width < 960 && (
                                <Icon
                                    component={Link}
                                    to={props.hrefLogo}
                                    className={`${styles.logo} ${props.classNameLogo} ${
                                        scroll ? props.classNameLogoFrontScroll : props.classNameLogoFront
                                    } `}
                                >
                                    <Box
                                        className={`${styles.imgLogo} ${
                                            scroll ? props.classNameLogoFrontScroll : props.classNameLogoFront
                                        }`}
                                    >
                                        {props.logo}
                                    </Box>
                                </Icon>
                            )}
                        </Box>
                        {props.standalone && (
                            <Icon className={styles.logoStandalone} component={Link} to={props.hrefLogo} onDragStart={ (e) => { e.preventDefault(); }}>
                                <Box className={styles.imgLogo}>{props.logo}</Box>
                            </Icon>
                        )}
                        {props.datas?.map((data, i) => (
                            <NavbarMenuList
                                {...props}
                                key={`navbar-data-${i}`}
                                scroll={scroll}
                                selected={selectedIndex === i + 1}
                                onClick={(event) => handleListItemClick(event, i + 1)}
                                data={data}
                                titlePopper={data?.sectionTitle}
                                menuItems={data?.menuItems}
                                width={data?.width}
                                placement={props.placement}
                                viewMore={data?.viewMore}
                                classNameContainerTitle={props.classNameContainerTitle}
                                classNameContainerLinks={props.classNameContainerLinks}
                                classNameContainerSubMenus={props.classNameContainerSubMenus}
                                index={i}
                            />
                        ))}
                        <>
                            {props.search && width >= 960 && (
                                <Icon className={styles.responsiveIconSearch} onClick={() => setDisplaySearch(true)}>
                                    <img
                                        src={searchIcon}
                                        style={{
                                            cursor: "pointer",
                                            filter: scroll ? darkColorSvg : "brightness(100)",
                                            width: "100%",
                                            height: "100%",
                                        }}
                                    />
                                </Icon>
                            )}
                            {width < 960 && (
                                <Box className={`${styles.containerRight} ${props.classNameContainerRight}`}>
                                    {props.search && (
                                        <Icon
                                            className={styles.responsiveIconSearch}
                                            onClick={() => setDisplaySearch(true)}
                                        >
                                            <img
                                                src={searchIcon}
                                                style={{
                                                    filter: scroll ? darkColorSvg : "brightness(100)",
                                                    width: width > 300 ? "1.5rem" : "auto",
                                                    height: width > 300 ? "1.5rem" : "auto",
                                                }}
                                            />
                                        </Icon>
                                    )}
                                    {(!props.standalone || (props.standalone && currentUser)) && (
                                        <Icon
                                            className={styles.account}
                                            style={{
                                                cursor: "pointer",
                                                position: "relative",
                                            }}
                                            onClick={() => {
                                                if (currentUser && width >= 960) {
                                                    setShowConnected(true);
                                                } else {
                                                    setShowAccount(true);
                                                }
                                            }}
                                        >
                                            {currentUser ? <span className={styles.connectedDot}></span> : null}
                                            <img
                                                src={account}
                                                style={{
                                                    width: width > 300 ? "1.5rem" : "auto",
                                                    height: width > 300 ? "1.5rem" : "auto",
                                                    display: "flex",
                                                    filter: scroll && darkColorSvg,
                                                }}
                                            />
                                        </Icon>
                                    )}
                                    <LanguageSelector scroll={scroll} />
                                </Box>
                            )}
                            {props.search && (
                                <Box className={styles.search}>
                                    <AutocompleteSearch
                                        onChange={(e) => setSearch(e)}
                                        startAdornment
                                        inputStyle={{
                                            backgroundColor: theme.palette.common.white,
                                            borderRadius: 50,
                                            height: 32,
                                            width: "100%",
                                            margin: "0 0 0 2rem"
                                        }}
                                        paperClassname={styles.autocompletePaper}
                                        placeholder={t`Rechercher...`}
                                        onEnterPressed={() =>
                                            search && history.push({ pathname: "/recherche/tout/resultats", search })
                                        }
                                        autocompleteContainerClassName={styles.autocompleteContainer}
                                        icon={inputSearchIcon}
                                        inputClassName={styles.autocompleteInput}
                                        iconLink="/recherche"
                                    />
                                </Box>
                            )}
                        </>
                    </Box>
                </Slide>
            </div>
            {props.search && (
                <div
                    id="slide-search"
                    style={{
                        overflow: width > 960 ? "inherit" : "hidden",
                        width: "100%",
                        display: !displaySearch && "none",
                    }}
                >
                    <Slide timeout={300} direction="down" in={displaySearch} mountOnEnter unmountOnExit>
                        <Box
                            className={styles.responsiveSearch}
                            style={{ display: !displaySearch && "none", height: width < 960 ? "60px" : "inherit" }}
                        >
                            <Icon
                                style={{
                                    cursor: "pointer",
                                    width: "fit-content",
                                    marginRight: "1rem",
                                    filter: scroll && darkColorSvg,
                                }}
                                onClick={() => {
                                    setOverflow(true);
                                    setDisplaySearch(false);
                                }}
                            >
                                <img src={arrow} />
                            </Icon>
                            <Box style={{ width: "100%" }}>
                                <AutocompleteSearch
                                    onChange={(e) => setSearch(e)}
                                    startAdornment
                                    inputStyle={{
                                        backgroundColor: theme.palette.common.white,
                                        borderRadius: 50,
                                        height: 32,
                                        width: "100%",
                                    }}
                                    paperClassname={styles.autocompletePaper}
                                    placeholder={t`Rechercher...`}
                                    onEnterPressed={() =>
                                        search && history.push({ pathname: "/recherche/tout/resultats", search })
                                    }
                                    autocompleteContainerClassName={styles.autocompleteContainer}
                                    icon={inputSearchIcon}
                                    inputClassName={styles.autocompleteInput}
                                    iconLink="/recherche"
                                />
                            </Box>
                        </Box>
                    </Slide>
                </div>
            )}
            {props.buttonBESV && (
                <Box className={styles.button}>
                    <Button
                        href={menuCTA(currentUser, paymentStatus).href}
                        size="small"
                        color="primary"
                        variant="contained"
                        style={{ width: "max-content" }}
                        classes={{
                            containedPrimary: styles.containedPrimary,
                        }}
                    >
                        <Typography variant="h5" className={styles.actionButton}>
                            {menuCTA(currentUser, paymentStatus, true).content}
                        </Typography>
                    </Button>
                </Box>
            )}
            {width >= 960 && (
                <Box
                    className={`${styles.containerRight} ${props.classNameContainerRight}`}
                    style={{ position: "relative", height: "75px" }}
                >
                    {(!props.standalone || (props.standalone && currentUser)) && (
                        <Icon
                            style={{ position: "relative" }}
                            className={styles.account}
                            onClick={() => {
                                if (currentUser) {
                                    setShowConnected(true);
                                } else {
                                    setShowAccount(true);
                                }
                            }}
                        >
                            {currentUser && <span className={styles.connectedDot}></span>}
                            <img
                                src={account}
                                style={{
                                    width: "1.5rem",
                                    height: "1.5rem",
                                    display: "flex",
                                    cursor: "pointer",
                                    filter: scroll && darkColorSvg,
                                }}
                            />
                        </Icon>
                    )}
                    <LanguageSelector scroll={scroll} />

                    {showConnected && (
                        <ClickAwayListener onClickAway={() => setShowConnected(false)}>
                            <Card className={styles.container_connected}>
                                {/* if connected */}
                                <BrowsingPreferenceModal
                                    noEnlish={
                                        currentUser && currentUser.subscriptionName !== SUBSCRIPTION_TYPES.journalist
                                    }
                                    registrationMode={!currentUser}
                                    open={warningBrowserLanguage}
                                    onClickBack={() => {
                                        setWarningBrowserLanguage(false);
                                    }}
                                    onClose={() => {
                                        setWarningBrowserLanguage(false);
                                    }}
                                />
                                <Typography
                                    variant="h5"
                                    style={{
                                        width: "100%",
                                        fontFamily: "Mansalva",
                                        color: theme.palette.secondary.dark,
                                    }}
                                >
                                    {currentUser?.wineEntityName ?? formatPerson(currentUser)}
                                </Typography>
                                <hr />
                                {currentUser?.wineEntityId ? (
                                    <>
                                        <Link className={styles.text_connected} to="/dashboard/estate/general">
                                            <Icon className={styles.icons_connected}>
                                                <img style={{ width: "100%", height: "100%" }} src={manage} />
                                            </Icon>
                                            <Typography
                                                variant="h5"
                                                style={{
                                                    color: theme.palette.primary.main,
                                                    lineHeight: "17px",
                                                    width: "max-content",
                                                }}
                                            >
                                                Gérer mes informations
                                            </Typography>
                                        </Link>

                                        <Link
                                            className={styles.text_connected}
                                            to={history.addLocale(
                                                `/${urlFormatted(
                                                    currentUser.wineEntityShortId,
                                                    currentUser.wineEntityName
                                                )}`
                                            )}
                                        >
                                            <Icon className={styles.icons_connected}>
                                                <img style={{ width: "100%", height: "100%" }} src={viewEstate} />
                                            </Icon>
                                            <Typography
                                                variant="h5"
                                                style={{
                                                    color: theme.palette.primary.main,
                                                    lineHeight: "17px",
                                                    width: "max-content",
                                                }}
                                            >
                                                Voir ma propriété
                                            </Typography>
                                        </Link>
                                    </>
                                ) : currentUser?.subscriptionName === SUBSCRIPTION_TYPES.ftReceiver ? (
                                    <Link to="/dashboard/sheets/inbox" className={styles.text_connected}>
                                        <Icon className={styles.icons_connected}>
                                            <img style={{ width: "100%", height: "100%" }} src={ftImage} />
                                        </Icon>
                                        <Typography
                                            variant="h5"
                                            style={{
                                                color: theme.palette.primary.main,
                                                lineHeight: "17px",
                                                width: "max-content",
                                            }}
                                        >
                                            Fiches techniques reçues
                                        </Typography>
                                    </Link>
                                ) : currentUser.subscriptionName === SUBSCRIPTION_TYPES.journalist ? (
                                    <Link to="/dashboard/sheets/inbox" className={styles.text_connected}>
                                        <Icon className={styles.icons_connected}>
                                            <img style={{ width: "100%", height: "100%" }} src={journalistImage} />
                                        </Icon>
                                        <Typography
                                            variant="h5"
                                            style={{
                                                color: theme.palette.primary.main,
                                                lineHeight: "17px",
                                                width: "max-content",
                                            }}
                                        >
                                            {ti18next("tastingRequest.myTastingRoom")}
                                        </Typography>
                                    </Link>
                                ) : (
                                    <SubscriptionInProgress />
                                )}

                                <Box className={styles.text_connected}>
                                    <Icon className={styles.icons_connected}>
                                        <img
                                            style={{ width: "100%", height: "100%" }}
                                            src={logout}
                                            onClick={handleSignOut}
                                        />
                                    </Icon>
                                    <Typography
                                        variant="h5"
                                        style={{
                                            color: theme.palette.primary.main,
                                            lineHeight: "17px",
                                            width: "max-content",
                                        }}
                                        onClick={handleSignOut}
                                    >
                                        {ti18next("common.logout")}
                                    </Typography>
                                </Box>
                            </Card>
                        </ClickAwayListener>
                    )}
                </Box>
            )}
        </Box>
    );
};

Navbar.propTypes = {
    datas: array,
    buttonBESV: bool,
    search: bool,
    logo: elementType,
    fioriture: bool,
    classNameContainerTitle: string,
    classNameContainerLinks: string,
    classNameContainerSubMenus: string,
    classNameSlideContent: string,
    classNameLogo: string,
    placement: string,
    hrefLogo: string,
    classNameNavbar: string,
    standalone: bool,
    classNameContainerRight: string,
    loadingMenuItems: bool,
    classNameLogoFront: string,
    classNameLogoFrontScroll: string,
    opaque: bool,
};

export default Navbar;
